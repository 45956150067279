import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { logOut, profile } from "../redux/actions";
import { useDispatch } from 'react-redux';
import { toastr } from "react-redux-toastr";
import profile_pc from "../Images/user-default.jpeg";
import logo from "../Images/wp_images/logo_white_blue.png";
import datingLogo from "../Images/wp_images/datingLogo.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NotificationBar from "./notificationBar";
import connection from "../config/connection";
function NavbarComponent(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory()
  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const auth = useSelector(state => state);
  const [user_state, setUserState] = React.useState("");
  const [profile_state, setProfileState] = React.useState(profile_pc);
  const [loaded, setLoaded] = useState(false);
  const [homeUrl, setHomeUrl] = useState('');
  const [baseName, setBaseName] = useState('home');


  useEffect(() => {

    if(splitLocation[1]=='dating')
    {
      setHomeUrl(connection.homePage+'home-dating');
      setBaseName('dating');
    }
    else
    {
      setHomeUrl(connection.homePage);
    }
    

    if (auth.auth.loginUserToken != '') {
      getProfile(auth.auth.loginUserToken);
    } 
    else if(sessionStorage.getItem("token")){
      getProfile(sessionStorage.getItem("token"));
    //  setUserState(auth.auth.loginUserInfo.first_name);
    //  setProfileState(auth.auth.loginUserInfo.avatar);
    }
  }, [auth]);

  const getProfile = (token) => {
    dispatch(profile({ token: token }, (result) => {
      if (result.data) {
        if(result.data && result.data.first_name != "") {
          setUserState(result.data.first_name);
        }
        
        if(result.data && result.data.avatar != "") {
          setProfileState(result.data.avatar);
        }
        
      } else if (result.detail && result.detail == "Authentication credentials were not provided.") {
        let payload = {
          "grant_type": "password",
          "client_id": auth.auth.credentials.client_id,
          "client_secret": auth.auth.credentials.client_secret,
          "token": auth.auth.loginUserToken
        }

        dispatch(logOut(payload, (result) => {
        }));
      }

    }));
  };

  const logout = () => {
    if(splitLocation[1]=='dating')
      {
        props.props.history.replace('/dating/logmeOut/');
      }
      else
      {
        props.props.history.replace('/oauth/logmeOut/');
      }
    // let payload = {
    //   "grant_type": "password",
    //   "client_id": auth.auth.credentials.client_id,
    //   "client_secret": auth.auth.credentials.client_secret,
    //   "token": auth.auth.loginUserToken
    // }

    // dispatch(logOut(payload, (result) => {
    //   if (result.status == true) {
    //     toast("You are successfully logged out of application.", {
    //       position: "top-right",
    //       autoClose: 3000,
    //       type: 'success',
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: false,
    //       progress: undefined,
    //     });
    //     sessionStorage.clear()
    //     setLoaded(!loaded);
    //     window.location.href = connection.homePage
    //     // props.props.history.replace('/oauth/sign-in/');
    //   }

    // }));
  };

  const datingArr = ['legacy']
  return (
    <>
    {auth.auth.loginUserToken &&
    
     <NotificationBar/>
    }
    <header className="new_header">
      {
        (loaded) &&
        <Redirect to="/oauth/sign-in/" />
      }
      <div className="container custom_h_container">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          
          <Link className="navbar-brand" onClick={()=>{window.location.href = auth.auth.loginUserToken ? homeUrl + `?access_token=${auth.auth.loginUserToken}`:homeUrl}}>
            {/* <h3>Eterna<span>view</span></h3><div className="beta-label">Beta</div> */}
            <img className="logo_custom_img" src={splitLocation[1]=='dating'?datingLogo:logo} alt="logo" />
          </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className={splitLocation[1] === "" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" onClick={()=>{  window.location.href = auth.auth.loginUserToken ? homeUrl + `?access_token=${auth.auth.loginUserToken}`:homeUrl}}>Home</Link>
              </li>
              {splitLocation[1]=='home'?(<li className={splitLocation[2] === "create" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" onClick={()=>{  window.location.href = auth.auth.loginUserToken ? connection.homePage + `create?access_token=${auth.auth.loginUserToken}`:connection.homePage+"create/"}}>Create</Link>
              </li>):(<li className={splitLocation[2] === "create" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" onClick={()=>{  window.location.href = auth.auth.loginUserToken ? connection.homePage + `create-dating?access_token=${auth.auth.loginUserToken}`:connection.homePage+"create-dating/"}}>Create</Link>
              </li>)}
              

              {splitLocation[1]=='home'?(<li className={splitLocation[2] === "interact" || splitLocation[2] === "interact-one" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/home/interact/">Interact</Link>
                </li>):(<li className={splitLocation[2] === "interact" || splitLocation[2] === "interact-one" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/dating/interact/">Interact</Link>
                  </li>)}

                  {splitLocation[1]=='home'?(<li className={splitLocation[2] === "pricing-plans" || splitLocation[2] === "pricing-plans" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/home/pricing-plans/">Pricing</Link>
                    </li>):(<li className={splitLocation[2] === "pricing-plans" || splitLocation[2] === "pricing-plans" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/dating/pricing-plans/">Pricing</Link>
                      </li>)}
              
             {/*  <li className={splitLocation[2] === "myeternaviews" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/home/myeternaviews/">My Collections</Link>
              </li> */}


              {splitLocation[1]=='home'?(<li className={splitLocation[2] === "myeternaviews" || splitLocation[2] === "myeternaviews" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/home/myeternaviews/">My Collections</Link>
                    </li>):(<li className={splitLocation[2] === "myeternaviews" || splitLocation[2] === "myeternaviews" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/dating/myeternaviews/">My Collections</Link>
                      </li>)}
              {/* {auth.auth.loginUserToken ? <li className={splitLocation[2] === "myeternaviews" ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/home/myeternaviews/">My Collections</Link>
              </li> : <></>} */}
              {auth.auth.loginUserToken ?
                <li className={splitLocation[2] === "profile" ? "nav-item dropdown login_outer4" : "nav-item dropdown"}>
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img className="smap" src={profile_state} />
                    Hello {user_state}
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to={'/'+baseName+'/profile'}>Profile</Link>
                    <a style={{cursor: "pointer"}} className="dropdown-item" onClick={logout}>Logout</a>
                  </div>
                </li>
                :
                
                
                splitLocation[1]=='home'?(<li className={splitLocation[2] === "sign-in" || splitLocation[2] === "sign-up" || splitLocation[2] === "reset-password"  ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/oauth/sign-in/">Login/Register</Link>
                  </li>):(<li className={splitLocation[2] === "sign-in" || splitLocation[2] === "sign-up" || splitLocation[2] === "reset-password"  ? "nav-item login_outer4" : "nav-item"}> <Link className="nav-link" to="/dating/sign-in/">Login/Register</Link>
                    </li>)
                
                }


                {splitLocation[1]=='home'?<li>
                 <a onClick={()=>{
                  sessionStorage.setItem("giftType",1)
                 history.push('/home/create/')
                 }} className="nav-link get_started_btn">Get Started</a>
                </li>:<li>
                 <a onClick={()=>{
                  sessionStorage.setItem("giftType",1)
                 history.push('/dating/create/')
                 }} className="nav-link get_started_btn">Get Started</a>
                </li>}
                
            </ul>
          </div>
        </nav>
      </div>
    </header>
    </>
   

  );
};

export default NavbarComponent;
